import React from "react";
import { Link } from "react-router-dom";
import "./Categorias.scss";
import { Redirect } from "react-router-dom";
import Ticker from "../ticker/Ticker";
import { isInt } from "../common/share-util";
import CarruselTestimonios from "../testimonios/CarruselTestimonios";
import CarruselNecesidades from "../necesidades/CarruselNecesidades";

class Categorias extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      redirectAPublica: false,
      countedUpCounters: false,
      montoDonacion: 200,
      errorMontoDonacion: false,
      irDonacionCheckout: false
    };
  }

  componentDidMount() {
    window.$('.apoya-alternativas-monte-popup').magnificPopup({
      type: 'image',
      mainClass: 'mfp-img-mobile split-img-donar',
      image: {
        verticalFit: true
      }
    });
    window.$('.apoya-alternativas-monte-popup').magnificPopup('open');
    window.$('.mfp-img-mobile .mfp-figure').on('click', (event)=>{
      let posX = event.clientX - event.currentTarget.getBoundingClientRect().x;
      if(posX < (event.currentTarget.getBoundingClientRect().width / 2)){
        //this.openPopup('https://google.com');
        console.log('left');
      }else{
        //this.openPopup('https://news.google.com');
        console.log('right');
      }
    });
    window.$('.mfp-img-mobile .mfp-close').on('click', (e)=>{
      e.stopPropagation();
      window.$('.apoya-alternativas-monte-popup').magnificPopup('close');
    });
  }

  componentDidUpdate() {
    if (this.props.redirectAPublica) {
      this.props.onDidRedirect(true);
      this.setState({ redirectAPublica: true });
    }
    if (this.props.totalNecesidades > 0 && !this.state.countedUpCounters && window.innerWidth > 767) {
      this.setState({
        countedUpCounters: true
      });
      window.$('.cic-counter').rCounter({
        duration: 30
      });
    }
  }

  componentWillUnmount() {
    if (this.props.firstLoadHome) {
      this.props.onLeaveHome();
    }
  }

  checkoutNecesidad(cantidad) {
    this.props.onSeleccionarNecesidadCheckout({
      ticket: '0000000',
      categoryName: 'Necesidades prioritarias',
      costoEstimado: cantidad
    });
  }

  actualizarMontoDonacion(event) {
    let value = event.target.value;
    if(isInt(value) || value === ''){
      this.setState({
        montoDonacion: value
      });
    }
  }

  donarAhoraCheckout(){
    if(this.state.montoDonacion >= 50){
      let monto = parseInt(this.state.montoDonacion);
      this.setState({
        irDonacionCheckout: true
      });
      this.props.onSeleccionarNecesidadCheckout({
        ticket: '0000000',
        categoryName: 'Necesidades prioritarias',
        costoEstimado: monto
      });
    }else{
      this.setState({
        errorMontoDonacion: true
      });
    }
  }

  openPopup(link){
    window.open(link, '_blank');
  }

  render() {
    if (this.state.redirectAPublica) {
      return <Redirect to="/publica" />
    }
    else if(this.state.irDonacionCheckout){
      return <Redirect push to="/datoscheckout" />
    }
    else {
      return (
        <React.Fragment>
          <a className="apoya-alternativas-monte-popup" href="https://apoyacic.s3.amazonaws.com/imagenes/popup_Alternativas_MonteCarmelo.jpg">
            <img src="https://apoyacic.s3.amazonaws.com/imagenes/popup_Alternativas_MonteCarmelo.jpg" width="75" height="75"></img>
          </a>
          <div className="mensaje-principal-container">
            <div className="mensaje-principal">
              <ul style={{ justifyContent: 'center' }} className="intro-stats">
                <li className="ciudadano-msg">
                  <div style={{ color: 'white' }} className="subtitulo-principal">De Ciudadano </div><div className="a-ciudadano">a Ciudadano</div>
                </li>
                <li style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="subtitulo-principal-msg"><span className="thin-mensaje">Te </span><span className="enfasis-mensaje">enlazamos con las necesidades </span><span className="thin-mensaje">de las organizaciones de la sociedad civil y ciudadanos que </span><span className="enfasis-mensaje">más lo necesitan.</span></div>
                </li>
              </ul>
              <ul style={{ justifyContent: 'center' }} className="intro-stats row2-mensaje">
                <li className="necesidades-abiertas-bloque">
                  <strong className="cic-counter">
                    {this.props.totalNecesidades.toLocaleString("es-MX")}
                  </strong>
                  <span className="stat-label-left">Necesidades abiertas</span>
                </li>
                <li className="beneficiados-large">
                  <strong className="cic-counter beneficiados-count">
                    {this.props.totalNecesidadesCerradas.toLocaleString("es-MX")}
                  </strong>
                  <span className="stat-label-left beneficiados-label">Beneficiados</span>
                </li>
                <li className="monto-cubrir-mobile">
                  <div className="monto-cubrir-cantidad">
                    <strong style={{ display: 'inline' }} className="counter-money-sign">$</strong>
                    <strong style={{ display: 'inline' }} className="cic-counter">
                      {this.props.costoTotalEstimado.toLocaleString("es-MX")}
                    </strong>
                  </div>
                  <span className="monto-a-cubrir-label">Monto a cubrir (MXN)</span>
                </li>
              </ul>
              <ul style={{ justifyContent: 'center' }} className="intro-stats row3-mensaje">
                <li className="monto-cubrir-large">
                  <div className="monto-cubrir-cantidad">
                    <strong style={{ display: 'inline' }} className="counter-money-sign">$</strong>
                    <strong style={{ display: 'inline' }} className="cic-counter">
                      {this.props.costoTotalEstimado.toLocaleString("es-MX")}
                    </strong>
                  </div>
                  <span className="monto-a-cubrir-label">Monto a cubrir (MXN)</span>
                </li>
                <li className="beneficiados-mobile">
                  <strong className="cic-counter beneficiados-count">
                    {this.props.totalNecesidadesCerradas.toLocaleString("es-MX")}
                  </strong>
                  <span className="stat-label-left beneficiados-label">Beneficiados</span>
                </li>
                <li style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="monto-cubierto-cantidad" style={{ display: 'flex', flexDirection: 'row' }}>
                    <strong style={{ display: 'inline' }} className="counter-money-sign">$</strong>
                    <strong style={{ display: 'inline' }} className="cic-counter">
                      {this.props.costoTotalCubierto.toLocaleString("es-MX")}
                    </strong>
                  </div>
                  <span style={{ color: 'white' }}>Monto cubierto (MXN)</span>
                </li>
              </ul>
              <ul className="intro-stats botones-wrapper">
                <li className="botones-apoyar">
                  <a className="btn-seccion-voluntariado" href="#seccion-apoyos-especie">Quiero ApoYAr</a>
                  <a className="btn-seccion-voluntariado-offset" href="#seccion-apoyos-especie-offset">Quiero ApoYAr</a>
                </li>
                <li className="botones-necesidad" style={{ display: 'flex', flexDirection: 'column' }}>
                  <Link className="btn-seccion-especie" to="/publica">Registrar necesidad</Link>
                  <Link className="btn-seccion-especie-offset" to="/publica">Registrar necesidad</Link>
                </li>
              </ul>
            </div>
          </div>
          <Ticker necesidades={this.props.necesidadesTicker}></Ticker>
          <div className="section margin-bottom-65">
            <div className="youtube-wrapper-principal">
              <iframe className="video-cic-apoya" width="50%" height="415" src="https://www.youtube.com/embed/9_m2AbEL5Ps" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              <div className="como-funciona-block">
                <h3>¿Cómo Funciona ApoYA?</h3>
                <div className="circulos-como-funciona">
                  <div className="circulo-como-funciona">
                    <img src="https://apoyacic.s3.amazonaws.com/imagenes/formulario_circulo_icono.png"></img>
                    <div className="mensaje-circulo">Recibimos necesidades de la población vulnerable.</div>
                  </div>
                  <div className="circulo-como-funciona">
                    <img src="https://apoyacic.s3.amazonaws.com/imagenes/microfono_circulo_icono.png"></img>
                    <div className="mensaje-circulo">Promoción a través de canales del CIC Mty.</div>
                  </div>
                  <div className="circulo-como-funciona">
                    <img src="https://apoyacic.s3.amazonaws.com/imagenes/manos_circulo_icono.png"></img>
                    <div className="mensaje-circulo">Logística, compra, recolección, y entrega.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-headline centered margin-top-0 margin-bottom-20 margin-top-20">
              <div id="seccion-apoyos-especie-offset"></div>
              <h3 id="seccion-apoyos-especie">Elige cómo ApoYAr</h3>
              <div className="descripcion-seccion">Las necesidades registradas fueron validadas por nuestro equipo</div>
            </div>
            <CarruselNecesidades onSeleccionarNecesidadCheckout={this.props.onSeleccionarNecesidadCheckout} necesidades={this.props.necesidadesTicker}></CarruselNecesidades>
            <div className="categorias-container">
              <div className="titulo-categorias">
                Ve todas las necesidades
              </div>
              <div className="subtitulo-categorias">
                Navega por categoría
              </div>
              <div className="seccion-categorias">
                <div className="columna-categorias">
                  {this.props.listaPorEspecie.map((categoria) => {
                    return (
                      <Link
                        key={categoria.id}
                        to={'/necesidades/' + categoria.id}
                        className="photo-box categoria-imagen"
                        data-background-image={categoria.image}
                        style={{
                          backgroundImage: "url(" + categoria.image + ")",
                        }}
                      >
                        <div className="photo-box-content">
                          <h3>{categoria.name}</h3>
                          {categoria.needsCount > 0 ? <span className="cantidad-necesidades">{categoria.needsCount}</span> : <span style={{ visibility: "hidden" }} className="cantidad-necesidades">{categoria.needsCount}</span>
                          }
                        </div>
                      </Link>
                    );
                  })}
                </div>

              </div>
            </div>
            <div id="donaciones" ></div>
            <div className="donaciones-y-boletin-wrapper">
              <div className="donaciones-block">
                <div className="donaciones-border-block">
                  <h3>Entre todos es más fácil</h3>
                  <div className="donaciones-subtitulo">También puedes donar la cantidad que tu desees para la bolsa ApoYA destinada a cubrir necesidades prioritarias.</div>
                  <div>
                    <Link className="boton-donacion button" to="/datoscheckout" onClick={this.checkoutNecesidad.bind(this, 50)}>$50</Link>
                    <Link className="boton-donacion-2 button" to="/datoscheckout" onClick={this.checkoutNecesidad.bind(this, 100)}>$100</Link>
                    <Link className="boton-donacion button" to="/datoscheckout" onClick={this.checkoutNecesidad.bind(this, 200)}>$200</Link>
                    <Link className="boton-donacion-2 button" to="/datoscheckout" onClick={this.checkoutNecesidad.bind(this, 500)}>$500</Link>
                  </div>
                  <div className="donar-ahora-wrapper">
                    {this.state.errorMontoDonacion && <div className="error-monto-mensaje">El monto mínimo son $50</div>}
                    <div style={{ marginBottom: '10px' }}>Otra cantidad:</div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span>$</span><input type="number" min="50" step="1" value={this.state.montoDonacion} onChange={this.actualizarMontoDonacion.bind(this)} className={'input-monto-donacion ' + (this.state.errorMontoDonacion ? 'error-monto' : '')}>
                      </input>
                      <button onClick={this.donarAhoraCheckout.bind(this)} className="boton-donar-ahora">Donar ahora</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="boletin-block">
                <div>
                  <img src="https://apoyacic.s3.amazonaws.com/imagenes/newsletter_icono_pagina_principal.png"></img>
                  <h3 style={{ textAlign: 'center' }} className="subtitulo-principal">¡Subscríbete</h3>
                  <div className="subtitulo-subscribete">a nuestro boletín!</div>
                  <div className="mensaje-subscribete-boletin">Mantente informado de nuevas necesidades y de como mejoramos nuestros esfuerzos para ApoYAr</div>
                  <link href="//cdn-images.mailchimp.com/embedcode/slim-10_7.css" rel="stylesheet" type="text/css" />
                  <div id="mc_embed_signup-2">
                    <form action="https://cic.us6.list-manage.com/subscribe/post?u=f516ac50bd7e30751b03ab890&amp;id=ae2d8cc00a" method="post" id="mc-embedded-subscribe-form-2" name="mc-embedded-subscribe-form-2" className="validate" target="_blank" noValidate>
                      <div id="mc_embed_signup_scroll-2">
                        <div>
                          <input type="email" defaultValue="" name="EMAIL" className="email cic-email-subscribe" id="mce-EMAIL-2" placeholder="Email" required />
                        </div>
                        {/*<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_f516ac50bd7e30751b03ab890_ae2d8cc00a" tabIndex="-1" defaultValue="" /></div>
                        <button title="Subscríbete" type="submit" readOnly name="subscribe" id="mc-embedded-subscribe-2" className="button cic-newsletter-subscribe-btn">Subscribirme</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="carruseles-seccion">
              <CarruselTestimonios></CarruselTestimonios>
            </div>
            <div className="comparte-principal margin-top-30">
              Comparte
            </div>
            <ul className="social-links">
              <li>
                <a className="facebook-compartir" href="https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&u=https://apoya.cic.mx/&sdk=joey&display=page&ref=plugin&src=share_button" target="_blank" title="Facebook" data-tippy-placement="bottom" data-tippy-theme="light">
                  <i className="icon-brand-facebook-f"></i>
                </a>
              </li>
              <li>
                <a className="twitter-compartir" href="https://twitter.com/intent/tweet?url=https://apoya.cic.mx/" target="_blank" title="Twitter" data-tippy-placement="bottom" data-tippy-theme="light">
                  <i className="icon-brand-twitter"></i>
                </a>
              </li>
              <li>
                <a className="linkedin-compartir" href="https://www.linkedin.com/shareArticle?mini=true&url=https://apoya.cic.mx/" target="_blank" title="LinkedIn" data-tippy-placement="bottom" data-tippy-theme="light">
                  <i className="icon-brand-linkedin-in"></i>
                </a>
              </li>
            </ul>
            <div className="comparte-principal">
              ¡Gracias por ApoYAr!
            </div>
          </div>
        </React.Fragment>
      );
    }

  }
}

export default Categorias;
