/* export const cicApiUrl = 'https://apoyacic.uc.r.appspot.com/';
export const paypalPaymentApiUrl = 'https://api.paypal.com/v1/payments/payment';
export const paypalPaymentMode = 'live';
export const paypalExperienceProfile = 'XP-Q6XB-LJGG-MA3K-SVN5'; */


export const cicApiUrl = 'https://apoyacic-test.uc.r.appspot.com/';
export const paypalPaymentApiUrl = 'https://api.sandbox.paypal.com/v1/payments/payment';
export const paypalPaymentMode = 'sandbox'; 
export const paypalExperienceProfile = 'XP-B8EK-WFW3-NUSC-T3ND';

export const MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];
export const WEEKDAYS_LONG = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
];
export const WEEKDAYS_SHORT = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];

export const isInt = function (value) {
    return !isNaN(value) &&
        parseInt(Number(value)) == value &&
        !isNaN(parseInt(value, 10));
}

export const subirImagen = function (bucketName, file, doneCallback) {
    window.AWS.config.region = 'us-east-1';
    window.AWS.config.credentials = new window.AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-east-1:268b81c5-3923-4aea-9c14-f7ad5d61a701',
    });
    let bucket = new window.AWS.S3({ params: { Bucket: bucketName } });
    function guid() { return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4(); }
    function s4() { return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1); }

    let llave = guid();
    file.nombre = llave;
    let nombre = llave;
    let params = { Key: llave, ContentType: file.type, Body: file, ServerSideEncryption: 'AES256' };
    bucket.putObject(params, function (err, data) {
        if (err) {
            console.log(err);
            doneCallback(err);
        } else {
            doneCallback(null, 'https://s3.amazonaws.com/' + bucketName + '/' + nombre);
        }
    }
    );
}

export const generarMobileMegaMenu = function (){
    var wi = window.$(window).width();
    if(wi <= '1099') {

        window.$(".mmenu-init" ).remove();
        window.$("#navigation").clone().addClass("mmenu-init").insertBefore("#navigation").removeAttr('id').removeClass('style-1 style-2')
                        .find('ul, div').removeClass('style-1 style-2 mega-menu mega-menu-content mega-menu-section').removeAttr('id');
        window.$(".mmenu-init").find("ul").addClass("mm-listview");
        window.$(".mmenu-init").find(".mobile-styles .mm-listview").unwrap();


        window.$(".mmenu-init").mmenu({
             "counters": true
        }, {
         // configuration
         offCanvas: {
            pageNodetype: "#wrapper"
         }
        });

        var mmenuAPI = window.$(".mmenu-init").data( "mmenu" );
        var $icon = window.$(".mmenu-trigger .hamburger");

        window.$(".mmenu-trigger").on('click', function() {
            mmenuAPI.open();
        });

    }
    window.$(".mm-next").addClass("mm-fullsubopen");
}